import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { useDispatch } from "react-redux";
import { navigate } from "gatsby";
import { useDarkMode } from "../ducks/colourModeSlice";
import { useUpdateHeaderActions } from "../ducks/headerActionsSlice";
import { loginPrimary, useAuthRedirects } from "../ducks/loginSlice";
import { TransitionContext } from "../layouts/transition";
import useWindowWidth from "../hooks/useWindowWidth";
import {
  initialFormValues as allFormValues,
  FormField,
  Error,
  changeFormValue,
  submitForm,
  anyValueEmpty,
} from "../utils/formHelpers";
import { H3, Body } from "../components/common/typography/";
import CTAButton from "../components/common/cta-button";
import SEO from "../components/common/seo";
import YearSealDark from "../components/images/YearSealDark";
import fullBottleDark from "../assets/images/FullBottleDark.png";

const initialFormValues = allFormValues.filter(
  (field) => field.key === "email"
);

const LoginPage = () => {
  const redirected = useAuthRedirects();
  const transitionStatus = useContext(TransitionContext);
  useDarkMode();
  useUpdateHeaderActions(
    {
      left: {
        text: "See Whisky",
        to: "/",
      },
      // right: {
      //   text: "Enter to Win",
      //   to: "/enter",
      // },
    },
    redirected || transitionStatus !== "entered"
  );

  const dispatch = useDispatch();
  const [formIsSaving, setFormIsSaving] = React.useState(false);
  const [fetchError, setFetchError] = React.useState(null);
  const [formValues, setFormValues] = React.useState(initialFormValues);

  const onSubmit = () =>
    submitForm({
      functionUrl: "/.netlify/functions/getBallot",
      successCallback: (response) => {
        dispatch(
          loginPrimary({
            code: response.code,
            email: response.email,
            fname: response.fname,
            friendCount: response.friendCount,
          })
        );
        navigate("/share");
      },
      formValues,
      setFormValues,
      setFetchError,
      setFormIsSaving,
    });

  const theme = useContext(ThemeContext);
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth && windowWidth <= theme.maxMobileSize;

  return (
    <Root>
      <SEO title="Log In" />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <H3>Log In</H3>
        <Tagline>
          Check in on how your ballot is doing and share your unique code.
        </Tagline>
        {formValues.map((field) => (
          <FormField
            key={field.key}
            field={field}
            onChange={changeFormValue(field.key, setFormValues, setFetchError)}
            noMargin
          />
        ))}
        <Error content={fetchError}>{fetchError}</Error>
        <CTAButton
          inverted
          spaced
          text={formIsSaving ? "Loading..." : "Log In"}
          action={onSubmit}
          fullWidth={isMobile}
          disabled={anyValueEmpty(formValues) || formIsSaving}
        />
      </Form>
      <SealWrapper>
        <YearSealDark />
      </SealWrapper>
    </Root>
  );
};

const Root = styled.div`
  padding-top: 146px;
  min-height: 100vh;
  width: 100%;
  text-align: left;
  background-image: url(${fullBottleDark});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 130%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 3fr;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    text-align: center;
    background: none;
    display: block;
  }
`;

const Form = styled.form`
  padding: ${({ theme }) => theme.spacing.xxl};
  padding-left: ${({ theme }) => theme.spacing.mega};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: ${({ theme }) => theme.spacing.md};
    align-items: center;
  }
`;

const Tagline = styled(Body)`
  margin: ${({ theme }) => `${theme.spacing.xxs} 0 ${theme.spacing.xl} 0`};
`;

const SealWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.xxl};
  padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    display: none;
  }
`;

export default LoginPage;
